import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import ReactHTMLDatalist from "react-html-datalist";
// import { addCliente } from "../helpers/rutaClientes";
// import { addComanda } from "../helpers/rutaComandas";
// import ActualizaComanda from "../components/ActualizaComanda"
import ActualizaComanda from "../components/ActualizaComanda";

import AddFormDynamics from "../components/AddFormDynamics";
// import { getUltimacomandas } from "../helpers/rutaUltimacomandas";
import { getClientes } from "../helpers/rutaClientes";
import { getUsuarios } from "../helpers/rutaUsuarios";
// import { getProducservs } from "../helpers/rutaProducservs";
import { getListas } from "../helpers/rutaListas";
import "../css/addcomandaform.css";

const AddComandaForm = () => {
  const [guardar, setGuardar] = useState(false);
  const [show, setShow] = useState(false);
  const [nrodecomanda, setNrodecomanda] = useState("");

  const [detail, setDetail] = useState({ food_id: "" });

  const handleChange = (e) => {
    setDetail({ ...detail, [e.target.name]: e.target.value });
    console.log(e.target.value);
  };
  // useEffect(() => {
  //   getUltimacomandas().then((comandas) => {
  //     setNrodecomanda({
  //       data: comandas,
  //       loading: false,
  //     });
  //   });
  // }, []);

  const [codcli, setCodcli] = useState("");
  const [razonsocial, setRazonsocial] = useState("");

  // useEffect(() => {
  //   axios("https://backditripollo.us-3.evennode.com/clientes")
  //     .then((res) => {
  //       setCodcli(res);
  //     })
  //     .catch((err) => console.log(err));
  // }, []);

  const [lista, setLista] = useState("");

  const [usuario, setUsuario] = useState("");

  const [formValues, setFormValues] = useState({
    nrodecomanda: "",
    codcli: "",
    lista: "",
    // usuario: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    // localStorage.setItem("nrodecomanda", nrodecomanda);
    console.log("e", codcli);
    if (codcli !== "") {
      var sp1=document.createTextNode(" : "+ razonsocial)
      console.log("hola");
      setGuardar(true);
      setShow(true);
      localStorage.setItem("codcli", codcli);
      localStorage.setItem("lista", lista);
      document.getElementById("lista").disabled = true;
      document.getElementById("button").disabled = true;
      const combo = document.querySelector("input.form-control");
      // combo.parentElement.removeChild(combo);
      combo.parentElement.replaceChild(sp1, combo);
   
    }
    else
        alert("Error - Seleccione correctamente el Cliente")
  };

  const [clientes, setClientes] = useState({
    data: {},
    loading: true,
  });
  useEffect(() => {
    getClientes().then((clientes) => {
      setClientes({
        data: clientes,
        loading: false,
      });
    });
  }, []);

  const [listas, setListas] = useState({
    data: {},
    loading: true,
  });
  useEffect(() => {
    getListas().then((listas) => {
      setListas({
        data: listas,
        loading: false,
      });
    });
  }, []);

  const [usuarios, setUsuarios] = useState({
    data: {},
    loading: true,
  });
  useEffect(() => {
    getUsuarios().then((usuarios) => {
      setUsuarios({
        data: usuarios,
        loading: false,
      });
    });
  }, []);

  var arraycliente = [];
  if (!clientes.loading) {
    for (let i = 0; i < clientes.data.clientes.length; i++) {
      arraycliente[i] = {
        text: clientes.data.clientes[i].razonsocial,
        value: clientes.data.clientes[i]._id,
      };
    }

    console.log("array", arraycliente);
  }

  return (
    <>
      {!clientes.loading && !listas.loading && !usuarios.loading && (
        <form onSubmit={handleSubmit}>
          <div className="container">
            <div className="form-row">
              <div className="form-group col-sm-4 mb-2">
                <label className="">Cliente</label>
                {/* <input
                  list="nrocom"
                  type="text"
                  className="form-control"
                  name="codcli"
                  id="codcli"
                  maxLength="30"
                  required
                  onChange={(e) => {
                    setCodcli(e.target.value);
                    console.log(e.target.value);
                  }}
                /> */}
                {console.log(clientes.data.clientes)}
                <ReactHTMLDatalist
                  classNames={"form-control"}
                  options={arraycliente}
                  required
                  onChange={(e) => {
                    setCodcli(e.target.value);
                    setRazonsocial(e.target.text);
                    console.log(e.target.value);
                  }}
                />
                {/* {console.log(clientes.data.clientes)} */}
                {/* <datalist id="nrocom">
                  {clientes.data.clientes.map((cliente) => (
                    <option value={cliente._id}>
                      {cliente.razonsocial} - RUTA:
                      {cliente.ruta.ruta}
                    </option>
                  ))}
                </datalist> */}
                {/* {localStorage.setItem("razonsocial", cliente.razonsocial)}; */}
                {/* </select> */}
              </div>

              <div className="form-group col-sm-2">
                <label className="">Lista</label>
                <select
                  type="text"
                  className="form-control"
                  name="lista"
                  id="lista"
                  maxLength="30"
                  required
                  onChange={(e) => {
                    setLista(e.target.value);
                  }}
                >
                  <option selected value="">
                    Elija opción
                  </option>
                  {listas.data.listas.map((lista) => (
                    <option value={lista._id}>{lista.lista}</option>
                  ))}
                </select>
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="btn btn-dark mt-4 mb-3"
                id="button"
                // onsubmit="return false"
                //onClick={guardar ? ActualizaComanda() : null}
              >
                Guardar Cabecera
              </button>
            </div>
          </div>
        </form>
      )}
      {/* {guardar ? ActualizaComanda() : null} */}
      {/* <AddFormDynamics guardar /> */}
      {show ? <AddFormDynamics guardar /> : null}
    </>
  );
};
export default AddComandaForm;
